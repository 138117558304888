import { useEffect, useState } from 'react';
import styles from './navbar-mobile.module.css';
import { useRouter } from 'next/router';

export function NavBarMobile() {
	const [showMenu, setShowMenu] = useState(false);
	const router = useRouter();
	useEffect(() => {setShowMenu(false);}, [router]);
	  return (
		<div className="showInMobile">
			<div className={styles.mobileNavContainer}  style={{display: `${showMenu ? 'block' : 'none'}`}}>
				<nav className={styles.navbar}>
					<div style={{fontSize: '24px',fontWeight: 600, marginTop: '16px'}}>Kategorier</div>
					<ul>
						<li><a href="/sexleketoy/dildo">Dildo</a></li>
						<li><a href="/sexleketoy/vibrator">Vibrator</a></li>
						<li><a href="/sexleketoy/analsex">Analsex</a></li>
						<li><a href="/sexleketoy/kondomer">Kondomer</a></li>
						<li><a href="/sexleketoy/womanizer">Womanizer</a></li>
						<li><a href="/sexleketoy/bdsm">BDSM</a></li>
						<li><a href="/sexleketoy/par">For par</a></li>
						<li><a href="/sexleketoy/fiftyshadesofgray">Fifty Shades of Gray</a></li>
						<li><a href="/sexleketoy/undertoy">Undertøy</a></li>
						<li><a href="/sexleketoy/pegging">Strap-on</a></li>
						<li><a href="/sexleketoy/sexhuske">Sexhuske</a></li>
						<li><a href="/rabattkoder">Rabattkoder</a></li>	
					</ul>	
					<div style={{fontSize: '22px',fontWeight: 600, marginTop: '16px'}}>Populære søk</div>
					<ul>
						<li><a href="/sok?q=womanizer">Womanizer</a></li>
						<li><a href="/sok?q=rabbit vibrator">Rabbit vibrator</a></li>
						<li><a href="/sok?q=penisring">Penisring</a></li>
						<li><a href="/sok?q=buttplug">Buttplug</a></li>
						<li><a href="/sok?q=brystvorteklemmer">Brystvorteklemmer</a></li>
						<li><a href="/sok?q=stor dildo">Stor dildo</a></li>
						<li><a href="/sok?q=glassdildo">Glass dildo</a></li>
						<li><a href="/sok?q=sort dildo">Sort dildo</a></li>
						<li><a href="/sok?q=naturlig dildo">Naturlig dildo</a></li>
						<li><a href="/sok?q=penispumpe">Penispumpe</a></li>
						<li><a href="/sok?q=penis sleeve">Penis sleeve</a></li>
						<li><a href="/sok?q=klitorisstimulator">Klitorisstimulator</a></li>
						<li><a href="/sok?q=handy runkemasking">The Handy Runkemaskin</a></li>
					</ul>
													
				</nav>
		  	</div>
		  	<button className={styles.fab} onClick={() => {
				setShowMenu(!showMenu);
			}}>
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">
					<title>menu</title>
					{!showMenu ? 
					<path fill="#fff" d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z" />
					:
					<path fill="#fff" d="M20 6.91L17.09 4L12 9.09L6.91 4L4 6.91L9.09 12L4 17.09L6.91 20L12 14.91L17.09 20L20 17.09L14.91 12L20 6.91Z" />
					}
				</svg>
			</button>
		</div>

  );
}