import styles from './navbar.module.css';
export default function PageNavbar() {
	return (
		<nav className={styles.navbar}>
			<ul>
				<li><a href="/sexleketoy/dildo">Dildo</a></li>
				<li><a href="/sexleketoy/vibrator">Vibrator</a></li>
				<li><a href="/sexleketoy/analsex">Analsex</a></li>
				<li><a href="/sexleketoy/kondomer">Kondomer</a></li>
				<li><a href="/sexleketoy/womanizer">Womanizer</a></li>
				<li><a href="/sexleketoy/bdsm">BDSM</a></li>
				<li><a href="/sexleketoy/par">For par</a></li>
				<li><a href="/sexleketoy/fiftyshadesofgray">Fifty Shades of Gray</a></li>
				<li><a href="/sexleketoy/undertoy">Undertøy</a></li>
				<li><a href="/sexleketoy/pegging">Strap-on</a></li>
				<li><a href="/sexleketoy/sexhuske">Sexhuske</a></li>
				<li><a href="/rabattkoder">Rabattkoder</a></li>
			</ul>		
		</nav>
	)
}