import styles from './page-footer.module.css';
import nytelseLogo from './../../assets/nytelse-logo.png';
import kondomerietLogo from './../../assets/kondomeriet-logo.png';
import joylandLogo from './../../assets/joyland-logo.png';
import sinfulLogo from './../../assets/sinful-logo.png';
import passionfruit from './../../assets/passionfruit-logo.png';
import merLykke from './../../assets/merlykke-logo.png';
import amorLogo from './../../assets/amor-logo.png';
export default function PageFooter() {
	return (
		<footer className={styles.footer}>
          	Billigesexleker.no
			<div style={{fontSize: '14px', marginTop: '8px'}}>Produkter fra de mest kjente norske leverandørene:</div>
			<div className={styles.logoContainer}>
				<a href="https://www.nytelse.no/tt/?tt=12509_12_263403_footer&r=%2F" target="_blank" rel="sponsored nofollow"><img className={styles.logo} src={nytelseLogo.src} style={{height: '24px'}} alt="Nytelse logo" /></a>
				<a href="https://www.kondomeriet.no/tt/?tt=31748_1690144_263403_&amp;r=" target="_blank" rel="sponsored nofollow"><img className={styles.logo} src={kondomerietLogo.src} style={{height: '42px'}} alt="Kondomeriet logo" /></a>
				<a href="https://tc.tradetracker.net/?c=36932&amp;m=2168215&amp;a=263403&amp;r=&amp;u=" target="_blank" rel="sponsored nofollow"><img className={styles.logo} src={joylandLogo.src} style={{height: '32px'}} alt="Joyland logo" /></a>
				<a href="https://amor.no/tt/?tt=33201_1799897_263403_&amp;r=" target="_blank" rel="nofollow noopener"><img className={styles.logo} src={amorLogo.src} style={{height: '32px'}} alt="Amor logo" /></a>
				<a href="https://www.partner-ads.com/no/klikbanner.php?partnerid=30465&bannerid=98110" target="_blank" rel="nofollow noopener"><img className={styles.logo} src={sinfulLogo.src} style={{height: '32px'}} alt="Sinful logo" /></a>
				<a href="https://clk.tradedoubler.com/click?p=203877&a=2485648" target="_blank" rel="nofollow noopener"><img className={styles.logo} src={passionfruit.src} style={{height: '32px'}} alt="Passionfruit logo" /></a>
				<a href="https://clk.tradedoubler.com/click?p=349424&a=2485648" target="_blank" rel="nofollow noopener"><img className={styles.logo} src={merLykke.src} style={{height: '32px'}} alt="MerLykke logo" /></a>
			</div>
			<a href="/om-oss" style={{fontSize: '12px'}}>Om Billigesexleker.no</a><br/>
			<span style={{fontSize: '12px'}}>E-post: post@billigesexleker.no</span><br/>
			<span style={{fontSize: '12px'}}>&copy; Copyright 2024</span>
		</footer>
	);
}